import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

import '../../generic-page.scss';

const CSDatabasePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>Database</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_database.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Database</h1>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Collectibles" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Characters"
            link="/counter-side/characters"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_employees.png"
                alt="Characters"
              />
            }
          />
          <CategoryCard
            title="Operators"
            link="/counter-side/operators"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_operators.png"
                alt="Operators"
              />
            }
          />
          <CategoryCard
            title="Ships"
            link="/counter-side/ships"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_ships.png"
                alt="Ships"
              />
            }
          />
        </Row>
        <SectionHeader title="Stats and data" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Character stats"
            link="/counter-side/database/character-stats"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_stats.png"
                alt="Character stats"
              />
            }
          />
          <CategoryCard
            title="Gear cheat sheet"
            link="/counter-side/database/gear-cheat-sheet"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_gearcheat.png"
                alt="Gear cheat sheet"
              />
            }
          />
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default CSDatabasePage;

export const Head: React.FC = () => (
  <Seo
    title="Database | Counter Side | Prydwen Institute"
    description="Database of characters, stats, skins for Counter Side."
  />
);
